









































import IconLoaderBar from '@/components/icon/loader/IconLoaderBar.vue';
import { useCiService } from '@/lib/Ci';
import { useRouter } from '@/router/use-router';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from '@vue/composition-api';
import { ToastProgrammatic } from 'buefy';
import { combineLatest, defer, Subscription } from 'rxjs';
import { concatMap, take, tap } from 'rxjs/operators';

export default defineComponent({
  components: { IconLoaderBar },
  setup() {
    const authS = useCiService('AuthService');
    const router = useRouter();

    // stages
    const stage = ref<'checking' | 'open' | 'error'>('checking');
    const stageChecking = computed(() => stage.value === 'checking');
    const stageOpen = computed(() => stage.value === 'open');
    const stageError = computed(() => stage.value === 'error');

    // listen changes thought authenticate event channel
    const eventChannelPipe = authS.eventChannel().pipe(
      concatMap(async (user) => {
        const redirectResult = await authS
          .getSigninRedirectResult()
          .catch(() => null);

        // current page not start from redirect signin
        const isNotRedirected = !redirectResult || !redirectResult.credential;
        const isRedirected = !isNotRedirected;

        // not signed yet
        if (isNotRedirected && user == null) stage.value = 'open';
        // signed, should go to proposal page.
        else if (isNotRedirected && !!user) {
          router.push({ name: 'proposal' });
        }
        //
        else if (isRedirected && user) {
          router.replace({ name: 'proposal' });
          ToastProgrammatic.open({
            type: 'is-success',
            message: `Welcome ${user.displayName}!`,
            position: 'is-top-right',
            queue: false,
          });
        }
        // shos error
        else stage.value = 'error';
      }),
    );

    let eventChannelSub: Subscription;
    onMounted(() => (eventChannelSub = eventChannelPipe.subscribe()));
    onBeforeUnmount(() => eventChannelSub.unsubscribe());

    const signin = async () => {
      authS.signinWithRedirect();
    };

    const s = {
      signin,
      stageChecking,
      stageOpen,
      stageError,
      stage,
    };
    return s;
  },
});
